import React from "react"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="无此页" />
  </Layout>
)

export default NotFoundPage
